/* eslint-disable no-plusplus */
export const isValidEmail = (value: string): boolean => {
   const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/gi;
   return emailRegex.test(value);
};

export const shuffleArray = <T>(array: T[]): T[] => {
   const shuffled = [...array];

   for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
   }

   return shuffled;
};

export const orderArray = <T>(array: T[]): T[] => {
   const ordered = [...array];
   function OrderLogic(a: any, b: any) {
      if (a.order == null) {
         return 0;
      }
      if (a.order < b.order) {
         return -1;
      }
      if (a.order > b.order) {
         return 1;
      }
      return 0;
   }

   return ordered.sort(OrderLogic);
};

export const getBase64FromUrl = async (url: string) => {
   const data = await fetch(url, { method: 'GET', headers: { 'Cache-Control': 'no-cache' } });
   const blob = await data.blob();
   return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
         const base64data = reader.result;
         resolve(base64data);
      };
   });
};

export const pxToRem = (value: number, baseFontSize = 16) => {
   const remValue = value / baseFontSize;
   return `${remValue}rem`;
};

export const hexToRgba = (hex: string, alpha = 1) => {
   const r = parseInt(hex.slice(1, 3), 16);
   const g = parseInt(hex.slice(3, 5), 16);
   const b = parseInt(hex.slice(5, 7), 16);

   return `rgba( ${r}, ${g}, ${b}, ${alpha})`;
};

export const detectWebview = () => {
   const { navigator } = window;
   const { userAgent } = navigator;
   const isStandaloneAvailable = 'standalone' in navigator;
   let standalone = null;
   if (isStandaloneAvailable) {
      standalone = navigator.standalone;
   }

   const normalizedUserAgent = userAgent.toLowerCase();

   const isIos =
      /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
   const isAndroid = /android/.test(normalizedUserAgent);
   const isSafari = /safari/.test(normalizedUserAgent);
   const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);

   // eslint-disable-next-line no-nested-ternary
   const osText = isIos ? 'iOS' : isAndroid ? 'Android' : 'Other';
   //   const webviewText = isWebview ? 'Yes' : 'No';
   let isMobile = false;

   if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
         userAgent
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
         userAgent.substring(0, 4)
      )
   ) {
      isMobile = true;
   }

   return { userAgent, Webview: isWebview, os: osText, isMobile };
};

export const tryParseInt = (str: string | undefined, defaultValue: number) => {
   let retValue = defaultValue;
   if (str !== undefined) {
      if (str.length > 0) {
         retValue = parseInt(str, 10);
      }
   }
   return retValue;
};

export const AnalyticsTrackPurchase = (transactionId: string, transactionItems: any, causes: number[]) => {
   const value = transactionItems.reduce((acc: number, item: any) => acc + item.amount, 0);
   const items = transactionItems.map((item: any) => ({
      quantity: 1,
      item_id: item.id,
      item_name: item.item_name,
      item_category: causes[0],
      item_category2: causes[1],
      item_category3: causes[2],
      item_variant: causes.join(','),
      price: item.amount,
   }));
   analytics?.track('purchase', {
      ecommerce: {
         transaction_id: transactionId,
         value,
         currency: 'USD',
         items,
      },
   });
};

export const populateUrl = (formatUrl: string, baseUrl?: string, publicName?: string, referralCode?: string) => {
   let formatedUrl = formatUrl;
   if (baseUrl && formatUrl.includes(':base_url')) {
      formatedUrl = formatedUrl.replace(':base_url', baseUrl);
   }

   if (publicName && formatUrl.includes(':public_name')) {
      formatedUrl = formatedUrl.replace(':public_name', publicName);
   }

   if (referralCode && formatUrl.includes(':referral_code')) {
      formatedUrl = formatedUrl.replace(':referral_code', referralCode);
   }

   return formatedUrl;
};

export const setLogoutUrlLocalStorage = (logoutUrl: string): void => {
   localStorage.setItem('logoutUrl', logoutUrl);
};
export const getLogoutUrlLocalStorage = (): string | null => {
   return localStorage.getItem('logoutUrl');
};
