import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, Container, Grid, Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { createPortal } from 'react-dom';
import { Link, useLocation } from 'react-router-dom';
import BonoLogo from 'components/bono-logo/bonologo';
import { MobileHeader } from 'components/header/mobileHeader';
import { NavMenu } from 'components/header/mobileHeader/mobileHeader';

export default function ProfileLayout({ children }: { children: React.ReactNode }): React.ReactElement {
   const location = useLocation();

   return (
      <>
         <MobileHeader />
         <Box
            sx={{
               paddingLeft: {
                  xs: 2,
                  md: 0,
               },
               paddingRight: {
                  xs: 2,
                  md: 3,
                  lg: 3,
               },
               zIndex: 1,
               overflow: 'hidden',
               background: {
                  xs: location.pathname.toLocaleLowerCase().includes('/p/')
                     ? 'linear-gradient(to bottom, #CCE4F5 210px, #F1F1F1 210px)'
                     : '#F1F1F1',
                  md: 'transparent',
               },
               position: 'relative',
               maxWidth: '1440px',
               margin: 'auto',
            }}
         >
            <Box sx={{ display: 'flex' }}>
               <Box
                  px={3}
                  pt={4}
                  sx={{
                     display: { xs: 'none', md: 'block' },
                     width: '280px',
                  }}
               >
                  <Box
                     sx={{
                        background: 'white',
                        height: '330px',
                        padding: '20px',
                        borderRadius: '4px',
                     }}
                  >
                     <NavMenu />
                  </Box>
               </Box>
               <Box sx={{ flex: 1, width: '100%' }}>{children}</Box>
            </Box>
         </Box>
         <Footer />
      </>
   );
}

function Footer(): React.ReactElement {
   const { t } = useTranslation();
   return (
      <Box sx={{ width: '100%', backgroundColor: '#333', padding: '30px', display: 'grid', gap: '14px' }}>
         <Box sx={{ display: 'flex', gap: '14px', justifyContent: 'center', alignItems: 'center' }}>
            <BonoLogo />
            <Box>
               <Typography
                  sx={{
                     color: '#fff',
                     fontFamily: 'Inter',
                     fontWeight: '700',
                     fontSize: '16px',
                     lineHeight: '19.36px',
                  }}
               />
            </Box>
         </Box>
         <SocialIcons />
         <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
            <Typography
               sx={{ color: '#fff', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px', lineHeight: '14.52px' }}
            >
               <Link
                  style={{ color: '#fff', textDecoration: 'none' }}
                  to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`}
                  target='_blank'
               >
                  <span style={{ minWidth: 'max-content' }}>{t('common:terms')}</span>
               </Link>{' '}
            </Typography>
            <Typography
               sx={{ color: '#fff', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px', lineHeight: '14.52px' }}
            >
               <Link
                  style={{ color: '#fff', textDecoration: 'none' }}
                  to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`}
                  target='_blank'
               >
                  <span style={{ minWidth: 'max-content' }}>{t('common:privacyPolicy')}</span>
               </Link>
            </Typography>
         </Box>
         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
               sx={{ color: '#fff', fontFamily: 'Inter', fontWeight: '400', fontSize: '12px', lineHeight: '14.52px' }}
            >
               BONO &copy;{new Date().getFullYear()} All rights reserved
            </Typography>
         </Box>
      </Box>
   );
}

function SocialIcons(): React.ReactElement {
   const { t } = useTranslation();
   return (
      <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center', color: '#fff' }}>
         <a href={t('socialLinks.linkedin')} rel='noreferrer' target='_blank'>
            <LinkedInIcon style={{ color: '#fff', width: '24px', height: '24px' }} />
         </a>
         <a href={t('socialLinks.instagram')} rel='noreferrer' target='_blank'>
            <InstagramIcon style={{ color: '#fff', width: '24px', height: '24px' }} />
         </a>
         <a href={t('socialLinks.mail')} rel='noreferrer' target='_blank'>
            <MailOutlineIcon style={{ color: '#fff', width: '24px', height: '24px' }} />
         </a>
      </Box>
   );
}
