import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';

import React, { ReactElement, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { causesState, handleSelectCause } from 'store/causes/causes.slice';
import { creatorsState, setSelectedCause, showCausesModal } from 'store/creators/creators.slice';

export interface StepComponentProps {
   getHeight: (height: number) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

interface SocialNetworkCardProps {
   id: string;
   name: string;
   icon: any;
   selected?: boolean;
   onClick: () => void;
}

function SocialNetworkCard({
   id,
   name,
   icon,
   selected,
   onClick,
}: SocialNetworkCardProps): ReactElement<HTMLButtonElement> {
   return (
      <Box
         onClick={onClick}
         sx={{
            background: '#fff',
            borderRadius: '8px',
            padding: { xs: '12px 8px', sm: '12px 8px 12px 12px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            cursor: 'pointer',
            gap: '10px',
            width: '100%',
            border: selected ? '2px solid #333' : '2px solid #fff',
            '& > svg': {
               '& > path': {
                  fill: '#333',
                  stroke: '#333',
               },
            },
         }}
      >
         <img
            style={{
               filter: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)',
               width: '32px',
               height: '32px',
            }}
            alt='cuase icon'
            src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
         />
         <Typography
            sx={{
               color: '#333',
               fontWeight: '600',
               fontSize: '14px',
               lineHeight: '16.94px',
               maxWidth: '8ch',
               overflowWrap: 'break-word',
            }}
         >
            {name}
         </Typography>
      </Box>
   );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
   height: 10,
   borderRadius: 5,
   [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#ddd',
   },
   [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#333',
   },
}));

export function SocialIssuesStep({ getHeight }: StepComponentProps): React.ReactElement {
   const containerRef = useRef<HTMLDivElement | null>(null);

   const dispatch = useAppDispatch();
   const { causesList } = useAppSelector(causesState);
   const { selectedCausesList } = useAppSelector(creatorsState);

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         getHeight(height);
      }
   }, []); // Runs once on mount.

   const maxCausesSelected = Number(process.env.REACT_APP_MAX_CAUSES_SELECTION);

   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '40px',
            gap: 1,
         }}
      >
         <Box>
            <Typography sx={{ fontWeight: 900, lineHeight: '38px' }} variant='h4' component='h1'>
               What social issues do you care about?
            </Typography>
         </Box>
         <Box sx={{ width: '100%', mb: 1 }}>
            <Typography sx={{ color: '#333', fontSize: '14px', fontWeight: '500', mb: 0.75 }}>
               {t('common:cuases.cuaseCount', {
                  selectedCauses: selectedCausesList?.length,
                  maxCauses: maxCausesSelected,
               })}
            </Typography>
            <BorderLinearProgress variant='determinate' value={Math.round(33.3 * selectedCausesList.length)} />
         </Box>
         <Box
            sx={{
               display: 'grid',
               gridTemplateColumns: {
                  xs: '95px 95px 95px',
                  sm: '110px 110px 110px',
               },
               gridTemplateRows: '110px 110px 110px',
               gap: '10px',
            }}
         >
            {causesList.map((item: any) => {
               return (
                  <SocialNetworkCard
                     onClick={() => {
                        if (selectedCausesList.includes(item.id)) {
                           dispatch(setSelectedCause([...selectedCausesList.filter((v) => v !== item.id)]));
                        } else if (selectedCausesList.length < 3) {
                           dispatch(setSelectedCause([...selectedCausesList, item.id]));

                           dispatch(handleSelectCause(item));
                        } else
                           dispatch(
                              showCausesModal({
                                 title: t('common:cuases.error.pick3limit', { maxCauses: maxCausesSelected }),
                                 text: t('common:cuases.error.pick3limitContent'),
                              })
                           );
                     }}
                     key={item.id}
                     id={item.id}
                     name={item.title}
                     icon={item.impactImage}
                     selected={selectedCausesList.includes(item.id)}
                  />
               );
            })}
         </Box>
      </Box>
   );
}
