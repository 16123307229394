import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { causesState, fetchSelectedCharities } from 'store/causes/causes.slice';
import { ButtonFurther, PageMainLabel, PageSubLabel } from 'components/common';
import { Icon } from 'components/icon';

import { authState } from 'store/auth/auth.slice';
import { ENUM_ICON } from 'enums/icons';
import { CauseTitle, CauseBoxItem, CauseTitleWrapper, CharitiesWrapper, CharityItemBox } from './styles';

export function PortfolioPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { t } = useTranslation();

   // const sessionDoneButton = sessionStorage.getItem('portfolioDoneButton');
   const { loggedUserData } = useAppSelector(authState);
   const [hasSubscription, setHasSubscription] = React.useState<boolean | undefined>(false);

   // const parsedSessionDoneBtn = sessionDoneButton && JSON.parse(sessionDoneButton);
   const displayedCauseTypes = new Set();
   // const displayedCauseTitles = new Set();
   // console.log(hasSubscription, loggedUserData?.subscription);

   const { charityList } = useAppSelector(causesState);

   useEffect(() => {
      setHasSubscription(loggedUserData?.subscription?.status === 'active');
   }, [loggedUserData]);

   useEffect(() => {
      if (charityList?.length === 0) {
         dispatch(fetchSelectedCharities());
      }
      document.title = `Portfolio | ${t('siteTitleName')}`;
   }, []);

   const differentButtonAction = () => {
      if (hasSubscription) {
         navigate(routes().myProfile);
      } else {
         navigate(routes().subscription);
      }
   };

   const generateNextBtnLabel = () => {
      if (hasSubscription) {
         return 'Save';
      }
      return 'Take Action';
   };

   useEffect(() => {
      if (charityList && charityList.length > 0) {
         charityList.forEach((charity) => {
            new Image().src = charity.image;
         });
      }
   }, [charityList]);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '20px' },
            justifyContent: { xs: 'space-between', sm: 'start' },
         }}
      >
         <Box
            component='div'
            width='100%'
            display='flex'
            flexDirection='column'
            sx={{
               pb: {
                  xs: 2.5,
                  sm: 0,
               },
            }}
         >
            <PageMainLabel>{t('common:givingPortfolio.heading')}</PageMainLabel>
            <PageSubLabel sx={{ marginBottom: '20px' }}>{t('common:givingPortfolio.subheading')}</PageSubLabel>
            <Box
               component='div'
               display='flex'
               gap={1.25}
               flexDirection='column'
               sx={{ width: { sm: '100%' }, mx: { sm: 'auto' } }}
            >
               {charityList.length > 0 &&
                  charityList.map((charity) => {
                     if (charity.causes.length > 0) {
                        for (const cause of charity.causes) {
                           if (!displayedCauseTypes.has(cause.title)) {
                              displayedCauseTypes.add(cause.title);
                              return (
                                 <CauseBoxItem key={cause.id}>
                                    <CauseTitleWrapper>
                                       <CauseTitle>{cause.title}</CauseTitle>
                                    </CauseTitleWrapper>
                                    <CharitiesWrapper>
                                       {charityList
                                          .filter((ch) => ch.causes.some((parentCause) => parentCause.id === cause.id))
                                          .map((ch) => (
                                             <CharityItemBox
                                                sx={{ cursor: 'pointer' }}
                                                key={ch.id}
                                                onClick={() => {
                                                   analytics?.track('view_charity', {
                                                      charity_id: ch.id,
                                                      charity_name: ch.title,
                                                   });
                                                   navigate(routes().charity.replace(':id', ch.id.toString()));
                                                }}
                                             >
                                                <img alt={ch.title} src={ch.icon} height='100%' width='100%' />
                                             </CharityItemBox>
                                          ))}
                                    </CharitiesWrapper>
                                 </CauseBoxItem>
                              );
                           }
                        }
                     }
                     return null;
                  })}
            </Box>
         </Box>

         <Box
            sx={{
               position: { xs: 'relative', sm: 'relative' },
               display: 'flex',
               bottom: { xs: 0 },
               width: '100%',
            }}
         >
            <ButtonFurther
               sx={{
                  marginBottom: 4,
                  width: { sm: '100%' },
                  mx: { sm: 'auto' },
               }}
               endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />}
               onClick={differentButtonAction}
            >
               {generateNextBtnLabel()}
            </ButtonFurther>
         </Box>
      </Box>
   );
}
