import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';

function ImpactUpdatesDisclaimer() {
   const { t } = useTranslation();
   return (
      <Box
         sx={{
            my: '15px',
            display: 'flex',
            gap: '8px',
         }}
      >
         <DynamicGoogleIcon iconSizeVariant='small' iconName='info' />
         <Typography
            sx={{
               fontSize: '12px',
               lineHeight: '15px',
            }}
         >
            {t('common:impactUpdates.disclaimer')}
         </Typography>
      </Box>
   );
}

export default ImpactUpdatesDisclaimer;
