import React, { useState, useRef } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import { Trans, useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { BasicModal } from 'modals/basic.modal';
import { ENUM_ICON } from 'enums/icons';
import { useAppDispatch } from 'store/hooks';
import { GoogleIcon, Icon } from 'components/icon';
import {
   addEmail,
   addName,
   addPassword,
   fetchLoggedUser,
   loginUserWithGoogleForAnonymous,
   sendSignupDataForAnonymous,
} from 'store/auth/auth.slice';
import {
   PageMainLabel,
   ButtonFurther,
   ContentWrapper,
   BottomContentBox,
   PageSubLabel,
   BasicButton,
   DividerComponent,
   FixedBottomNav,
} from 'components/common';
import { LoggedUserTypes } from 'store/auth/types';
import { isValidEmail, detectWebview } from 'utils/utils';

export function AnonymousConnectPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const { t } = useTranslation();
   const [error, setError] = useState(false);
   const [dialog, setDialog] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const [email, setEmail] = useState('');
   const [name, setName] = useState('');
   const [alert, setAlert] = useState(`${t('saveYourPortfolio.emailExistsErrorText')}`);

   const handleNext = () => {
      if (name.length >= 2 && isValidEmail(email)) {
         setError(false);
         setSubmitting(true);
         dispatch(addName(name));
         dispatch(addEmail(email));
         dispatch(sendSignupDataForAnonymous()).then(({ payload }) => {
            if (payload.response?.status === 409) {
               setDialog(true);
               setSubmitting(false);
               return;
            }
            dispatch(addPassword(payload.password));
            dispatch(fetchLoggedUser()).then((res) => {
               setSubmitting(false);
               navigate(routes().subscription);
            });
         });
      } else {
         setSubmitting(false);
         setError(true);
      }
   };

   const getHelperText = () => {
      if (error && !isValidEmail(email)) {
         return `${t('common:saveYourPortfolio.emailErrorMessage')}`;
      }
      return '';
   };

   const getHelperNameText = () => {
      if (error && name.length < 3) {
         return `${t('saveYourPortfolio.nameErrorMessage')}`;
      }
      return null;
   };

   const googleLogin = useGoogleLogin({
      onSuccess: async (codeResponse) => {
         try {
            const response = await dispatch(loginUserWithGoogleForAnonymous(codeResponse.access_token));
            if (response.payload.response?.status === 409) {
               setDialog(true);
               return;
            }
            if (response.payload) {
               navigate(routes().subscription);
            }
         } catch (errorA) {
            console.log('Google error', errorA);
         }
      },
      onError: (errorA) => {
         setDialog(true);
         setAlert('Failed to login with google');
         console.log('Login Failed:', errorA);
      },
   });

   const loginWithGoogle = () => {
      sessionStorage.clear();
      googleLogin();
   };

   const userBrowserData = detectWebview();

   return (
      <PageWrapper
         logo={false}
         logoSpace={false}
         topPadding={false}
         leftButton={{ onClick: () => navigate(routes().portfolio) }}
      >
         <ContentWrapper sx={{ pb: { xs: 11.5, sm: 0 } }}>
            <Box
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               gap={2.5}
               sx={{
                  pb: {
                     xs: 2.5,
                     sm: 0,
                  },
               }}
            >
               <PageMainLabel>{t('common:saveYourPortfolio.heading')}</PageMainLabel>
               <PageSubLabel>
                  <Trans i18nKey='common:saveYourPortfolio.subheading' components={{ 1: <br /> }} />
               </PageSubLabel>

               <Box component='div' mt={3.75} width='100%' display='flex' flexDirection='column' gap={2.5}>
                  {!userBrowserData.Webview ? (
                     <Box
                        component='div'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        sx={{ maxWidth: { sm: '346px' }, mx: 'auto' }}
                     >
                        <BasicButton fullWidth startIcon={<GoogleIcon />} onClick={loginWithGoogle}>
                           {t('common:insertYourCode.buttonCTA')} With Google
                        </BasicButton>
                     </Box>
                  ) : (
                     ''
                  )}
                  {!userBrowserData.Webview && <DividerComponent mt={2.5} mb={2.5} />}
                  <Box
                     component='div'
                     width='100%'
                     display='flex'
                     flexDirection='column'
                     gap={2.5}
                     sx={{ maxWidth: { sm: '346px' }, mx: 'auto' }}
                  >
                     <TextField
                        fullWidth
                        required
                        ref={inputRef}
                        autoComplete='off'
                        className='CommonInput'
                        placeholder={t('common:saveYourPortfolio.inputText1')}
                        type='text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        helperText={getHelperNameText()}
                        FormHelperTextProps={{
                           className: 'helper-text',
                        }}
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              border: getHelperNameText() === null ? '' : '2px solid red',
                           },
                        }}
                     />
                     <TextField
                        fullWidth
                        required
                        ref={inputRef}
                        autoComplete='off'
                        className='CommonInput'
                        placeholder={t('common:saveYourPortfolio.inputText2')}
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        helperText={getHelperText()}
                        FormHelperTextProps={{
                           className: error ? 'helper-text' : 'helper-text-comment',
                        }}
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              border: getHelperNameText() === null ? '' : '2px solid red',
                           },
                        }}
                     />
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  position: { xs: 'fixed', lg: 'relative' },
                  bottom: { xs: 0 },
                  width: '100%',
               }}
            >
               <FixedBottomNav
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: 2,
                     height: 'auto',
                     borderTop: { xs: '1px solid #D3D3D3', lg: 'none' },
                     paddingTop: 2,
                     opacity: { xs: 0.9, sm: 1 },
                     background: { xs: '#F7F7F7', sm: 'transparent' },
                  }}
               >
                  <ButtonFurther
                     sx={{
                        backgroundColor: '#262627',
                        marginBottom: 4,
                        width: { lg: '240px' },
                        mx: { lg: 'auto' },
                     }}
                     endIcon={submitting ? null : <Icon name={ENUM_ICON.ARROW_RIGHT} />}
                     disabled={submitting}
                     onClick={handleNext}
                  >
                     {submitting ? (
                        <CircularProgress size={24} color='white' />
                     ) : (
                        `${t('common:saveYourPortfolio.buttonCTA')}`
                     )}
                  </ButtonFurther>
               </FixedBottomNav>
            </Box>
         </ContentWrapper>
         <BasicModal
            open={dialog}
            onClose={() => setDialog(false)}
            onNext={() => {
               localStorage.clear();
               navigate(routes().loginEmail);
            }}
            title={t('saveYourPortfolio.emailExistsErrorHeading')}
            description={alert}
            buttonLabel='Log in'
         />
      </PageWrapper>
   );
}
