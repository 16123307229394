import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { GoogleIcon, Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import {
   PageMainLabel,
   PageSubLabel,
   ButtonFurther,
   ContentWrapper,
   BottomContentBox,
   HaveLinkText,
   DividerComponent,
   BasicButton,
   LoadingIcon,
} from 'components/common';
import PasswordTextField from 'components/password-textfield';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { detectWebview, isValidEmail } from 'utils/utils';
import { PageWrapper } from 'wrappers';
import {
   addEmail,
   addName,
   addPassword,
   authState,
   createAnonymousSignup,
   fetchLoggedUser,
   fetchVerificationCode,
   loginUserWithGoogle,
   loginUserWithGoogleForAnonymous,
   sendMagicLink,
} from 'store/auth/auth.slice';
import { LoggedUserTypes } from 'store/auth/types';
import { BasicModal } from 'modals/basic.modal';

export function SignUpPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const { signupData } = useAppSelector(authState);
   const { fetchVerCodeError, sendNewUserWithCodeError } = useAppSelector(authState);
   const [email, setEmail] = useState('');
   const [alert, setAlert] = useState(false);
   const [alertName, setAlertName] = useState(false);
   const [isFocused, setIsFocused] = useState(false);
   const [name, setName] = useState(signupData?.firstName);
   const [error, setError] = useState(false);
   const [dialog, setDialog] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const { t } = useTranslation();

   const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail = e.target.value;
      if (newEmail === '') {
         setAlert(false);
      }
      setEmail(newEmail);
   };

   const handleNameChange = (newName: string) => {
      if (newName.length === 0) {
         setError(false);
      }
      setName(newName);
      dispatch(addName(newName));
   };

   const handleNext = () => {
      if (submitting) return;
      if (!isValidEmail(email)) {
         setAlert(true);
         return;
      }
      if (name.length >= 3) {
         setError(false);
         setAlertName(false);
         setSubmitting(true);
         dispatch(addEmail(email));
         dispatch(addName(name));

         dispatch(createAnonymousSignup())
            .then(({ payload }) => {
               if (payload.response && payload.response?.status === 409) {
                  dispatch(sendMagicLink()).then((res) => {
                     navigate(routes().checkYourEmail);
                  });
               } else if (payload.user) {
                  if (payload.user.details.creatorCoupon) {
                     navigate(`${routes().creatorFlow.base}/${routes().creatorFlow.creatorImpact}`);
                  } else {
                     navigate(routes().causes);
                  }
               } else {
                  dispatch(addEmail(''));
                  dispatch(addName(''));
               }
            })
            .finally(() => {
               setSubmitting(false);
            });
      } else {
         setAlertName(true);
         return;
      }

      setAlert(false);
   };

   useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
         if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
         }
      };
      if (inputRef && inputRef.current) {
         inputRef.current.addEventListener('keypress', handleKeyPress);
      }

      return () => {
         if (inputRef && inputRef.current) {
            inputRef.current.removeEventListener('keypress', handleKeyPress);
         }
      };
   }, [email]);

   const getHelperText = () => {
      if (alert) {
         return t('common:saveYourPortfolio.emailErrorMessage');
      }
      if (fetchVerCodeError) {
         return 'Wrong Email Provided';
      }
      if (sendNewUserWithCodeError) {
         return 'Activation Code was not found';
      }
      return null;
   };

   const googleLogin = useGoogleLogin({
      onSuccess: async (codeResponse) => {
         try {
            const response = await dispatch(loginUserWithGoogleForAnonymous(codeResponse.access_token));
            if (response.payload?.user) {
               const { user } = response.payload as LoggedUserTypes;

               if (user.subscription) {
                  navigate(routes().myProfile);
               } else if (user.details?.creatorCoupon) {
                  navigate(`${routes().creatorFlow.base}/${routes().creatorFlow.creatorImpact}`);
               } else {
                  navigate(routes().causes);
               }
            }
         } catch (errorA) {
            console.log('Google error', errorA);
         }
      },
      onError: (errorA) => console.log('Login Failed:', errorA),
   });

   const userBrowserData = detectWebview();

   const loginWithGoogle = () => {
      sessionStorage.clear();
      googleLogin();
   };

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            pl: { sm: '30px' },
            pr: { sm: '30px' },
         }}
      >
         <ContentWrapper
            sx={{
               justifyContent: { xs: 'space-between', sm: 'start' },
            }}
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' gap={1.25} mt={{ sm: 0 }} mb={3.75}>
               <PageMainLabel>{t('common:signUp.heading')}</PageMainLabel>
               <PageSubLabel>
                  {t('common:signUp.subheading')}
                  <Link to={routes().signIn} style={{ color: '#666666' }}>
                     Log in
                  </Link>
               </PageSubLabel>

               {!userBrowserData.Webview ? (
                  <>
                     <Box
                        component='div'
                        sx={{ maxWidth: { xs: '100%' }, marginX: 'auto' }}
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        gap={2.5}
                        mt={3.75}
                     >
                        <BasicButton fullWidth startIcon={<GoogleIcon />} onClick={loginWithGoogle}>
                           {t('common:insertYourCode.buttonCTA')} With Google
                        </BasicButton>
                     </Box>
                     <DividerComponent mb={2.5} mt={2.5} />
                  </>
               ) : (
                  ''
               )}

               <TextField
                  fullWidth
                  required
                  className='CommonInput'
                  value={name}
                  type='text'
                  ref={inputRef}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onChange={(e) => handleNameChange(e.target.value)}
                  error={error}
                  helperText={alertName ? t('common:signUp.nameErrorMessage') : ''}
                  placeholder={t('common:signUp.inputText1')}
                  sx={{ maxWidth: { xs: '100%' }, margin: 'auto' }}
               />

               <TextField
                  fullWidth
                  required
                  ref={inputRef}
                  autoComplete='off'
                  className='CommonInput'
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  placeholder={t('common:signUp.inputText2')}
                  type='email'
                  value={email}
                  error={alert || !!fetchVerCodeError || !!sendNewUserWithCodeError}
                  onChange={handleEmailChange}
                  helperText={getHelperText()}
                  sx={{ maxWidth: { xs: '100%' }, margin: 'auto' }}
               />
            </Box>

            <BottomContentBox isFocused={false} focusedValue='calc(-100% + 642px)'>
               <ButtonFurther
                  sx={{ width: { xs: '100%', sm: '100%' }, margin: 'auto' }}
                  endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />}
                  onClick={handleNext}
               >
                  {submitting ? <LoadingIcon /> : t('common:insertYourCode.buttonCTA')}
               </ButtonFurther>
            </BottomContentBox>
         </ContentWrapper>
         <BasicModal
            open={dialog}
            onClose={() => setDialog(false)}
            onNext={() => navigate(routes().loginEmail)}
            title={t('saveYourPortfolio.emailExistsErrorHeading')}
            description='Your email or password is wrong, please try again'
            buttonLabel='Log in'
         />
      </Box>
   );
}
