import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { createPortal } from 'react-dom';
import { t } from 'i18next';
import { detectWebview } from 'utils/utils';
import BonoLogo from 'components/bono-logo/bonologo';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';

export function MediumBox(props: { icon: React.ReactNode; number: React.ReactNode; text: React.ReactNode }) {
   const { icon, number, text } = props;
   return (
      <Box sx={{ backgroundColor: '#fff', padding: '20px', borderRadius: '4px' }}>
         <Box>{icon}</Box>
         <Typography
            sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '26px', lineHeight: '31.47px' }}
         >
            {number}
         </Typography>
         <Typography
            sx={{ fontFamily: 'Inter', color: '#999', fontSize: '12px', fontWeight: '600px', lineHeight: '14.52px' }}
         >
            {text}
         </Typography>
      </Box>
   );
}

export function SmallBox(props: { selected: boolean; color: string; text: string }) {
   const { selected, color, text } = props;
   return (
      <Box
         sx={{
            backgroundColor: color || '#fff',
            borderRadius: '4px',
            position: 'relative',
            aspectRatio: '113/86',
            border: selected ? '2px solid rgba(0,0,0,0.3)' : 'none',
         }}
      >
         {selected && (
            <Box
               sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '24px',
                  height: '24px',
                  transform: 'translateX(30%) translateY(-30%)',
               }}
            >
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  style={{ width: '100%', height: '100%' }}
                  fill='none'
                  viewBox='0 0 24 24'
               >
                  <rect width='24' height='24' fill='#333' rx='12' />
                  <path
                     fill='#fff'
                     d='M17.592 8.008a.833.833 0 00-1.184 0L10.2 14.225l-2.608-2.617a.852.852 0 00-1.184 1.225l3.2 3.2a.833.833 0 001.184 0l6.8-6.8a.834.834 0 000-1.225z'
                  />
               </svg>
            </Box>
         )}
         <Box sx={{ p: '16px 12px' }}>
            <Typography
               sx={{
                  color: selected ? '#333' : '#BBB',
                  fontFamily: 'Inter',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '18px',
               }}
            >
               {text}
            </Typography>
         </Box>
      </Box>
   );
}

export function AboutBono({ shareLink, isPublic }: { shareLink: string; isPublic: boolean }) {
   const langPrefix = `${!isPublic ? 'private' : 'public'}.aboutBono`;
   return (
      <Box
         sx={{
            backgroundColor: '#fff',
            borderRadius: '4px',
            padding: '20px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '700px',
            margin: '0 auto',
         }}
      >
         <Typography
            sx={{
               fontFamily: 'Inter',
               color: '#333',
               fontWeight: '700',
               fontSize: '16px',
               lineHeight: '19.36px',
            }}
         >
            {t(`${langPrefix}.heading`, { ns: 'widgets' })}
         </Typography>
         <Typography
            sx={{
               fontFamily: 'Inter',
               color: '#333',
               fontWeight: '400',
               fontSize: '16px',
               lineHeight: '19.36px',
            }}
         >
            {t(`${langPrefix}.share`, { ns: 'widgets' })}
         </Typography>
         <Box sx={{ margin: '10px 0' }}>
            <ShareButton
               CTALink={shareLink}
               buttonText={t(`${langPrefix}.shareCTA`, { ns: 'widgets' })}
               showPopup
               width='100%'
            />
         </Box>
      </Box>
   );
}

export function ShareButton({
   buttonText,
   arrow = true,
   width = 'fit-content',
   showPopup = false,
   CTALink = '',
}: {
   buttonText?: string;
   arrow?: boolean;
   width?: string;
   showPopup?: boolean;
   CTALink?: string;
}) {
   const [sharePopupActive, setSharePopupActive] = useState(false);
   const [linkCopy, setLinkCopy] = useState(false);

   useEffect(() => {
      sharePopupActive ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'scroll');
   }, [sharePopupActive]);

   const handleClick = () => {
      if (showPopup) {
         if (
            detectWebview().isMobile &&
            CTALink &&
            navigator.share &&
            navigator.canShare({
               title: buttonText,
               text: CTALink,
            })
         ) {
            navigator.share({
               title: buttonText,
               text: CTALink,
            });
         } else {
            setSharePopupActive(true);
         }
      } else {
         window.location.href = CTALink;
      }
   };

   return (
      <>
         {showPopup &&
            sharePopupActive &&
            createPortal(
               <dialog
                  style={{
                     width: '100dvw',
                     height: '100dvh',
                     background: 'rgba(0, 0, 0, 0.6)',
                     position: 'fixed',
                     inset: '0px',
                     zIndex: '99999999999999999999999999999',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
                  open
               >
                  <Box
                     sx={{
                        width: '480px',
                        minHeight: '420px',
                        backgroundColor: '#F1F1F1',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                        padding: '40px',
                        gap: '20px',
                        position: 'relative',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           top: '16px',
                           right: '16px',
                           color: '#000',
                           height: '24px',
                           width: '24px',
                           fontSize: '24px',
                           textAlign: 'center',
                           lineHeight: '24px',
                           cursor: 'pointer',
                        }}
                        onClick={() => {
                           setSharePopupActive(false);
                        }}
                     >
                        <DynamicGoogleIcon iconName='Close' />
                     </Box>
                     <Box
                        component='div'
                        sx={{
                           height: 110,
                        }}
                     >
                        <BonoLogo width='110px' />
                     </Box>

                     {CTALink && (
                        <>
                           <Typography
                              sx={{
                                 fontFamily: 'Inter',
                                 fontWeight: 900,
                                 fontSize: '36px',
                                 lineHeight: '38px',
                                 color: '#333',
                                 marginTop: '10px',
                              }}
                           >
                              {t('common:myProfile.sharePopupHeading')}
                           </Typography>
                           <Typography
                              sx={{
                                 fontFamily: 'Inter',
                                 fontWeight: 400,
                                 fontSize: '16px',
                                 lineHeight: '19.36px',
                                 color: '#333',
                                 marginTop: '10px',
                              }}
                           >
                              {t('common:myProfile.sharePopupText')}
                           </Typography>
                           <Box
                              sx={{
                                 width: '100%',
                                 background: '#fff',
                                 border: '2px solid #ccc',
                                 borderRadius: '8px',
                                 height: '48px',
                                 padding: '0px 20px',
                                 boxSizing: 'border-box',
                                 display: 'flex',
                                 fontSize: '14px',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                              }}
                           >
                              <div>{CTALink}</div>
                              <FileCopyIcon
                                 onClick={() => {
                                    navigator?.clipboard.writeText(CTALink);
                                    setLinkCopy(true);
                                 }}
                                 sx={{
                                    width: '24px',
                                    height: '24px',
                                    cursor: 'pointer',
                                 }}
                              />
                           </Box>

                           <Typography
                              component='span'
                              fontSize={16}
                              fontWeight={600}
                              color='#3CD39D'
                              display={linkCopy ? 'visible' : 'none'}
                              visibility={linkCopy ? 'visible' : 'hidden'}
                              sx={{ position: 'static' }}
                           >
                              {t('common:linkCopied')}
                           </Typography>

                           <Box
                              sx={{
                                 width: '100%',
                                 height: '44px',
                                 padding: '0px 20px',
                                 boxSizing: 'border-box',
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                                 marginTop: '10px',
                              }}
                           >
                              <Box
                                 component='a'
                                 href={`https://www.facebook.com/sharer/sharer.php?u=${CTALink}`}
                                 target='_blank'
                                 sx={{
                                    width: '44px',
                                    height: '44px',
                                    background: '#fff',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer',
                                 }}
                              >
                                 <FacebookIcon
                                    sx={{
                                       width: '24px',
                                       height: '24px',
                                       fill: '#333',
                                    }}
                                 />
                              </Box>
                              <Box
                                 component='a'
                                 href={`https://twitter.com/intent/tweet?text=${CTALink}`}
                                 target='_blank'
                                 sx={{
                                    width: '44px',
                                    height: '44px',
                                    background: '#fff',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer',
                                 }}
                              >
                                 <TwitterIcon
                                    sx={{
                                       width: '24px',
                                       height: '24px',
                                       fill: '#333',
                                    }}
                                 />
                              </Box>
                              <Box
                                 component='a'
                                 href={`https://www.linkedin.com/sharing/share-offsite/?url=${CTALink}`}
                                 target='_blank'
                                 sx={{
                                    width: '44px',
                                    height: '44px',
                                    background: '#fff',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer',
                                 }}
                              >
                                 <LinkedInIcon
                                    sx={{
                                       width: '24px',
                                       height: '24px',
                                       fill: '#333',
                                    }}
                                 />
                              </Box>
                              <Box
                                 component='a'
                                 href={`whatsapp://send?text=${CTALink}`}
                                 target='_blank'
                                 sx={{
                                    width: '44px',
                                    height: '44px',
                                    background: '#fff',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer',
                                 }}
                              >
                                 <WhatsAppIcon
                                    sx={{
                                       width: '24px',
                                       height: '24px',
                                       fill: '#333',
                                    }}
                                 />
                              </Box>
                           </Box>
                        </>
                     )}
                  </Box>
               </dialog>,
               document.body
            )}
         <Box style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={handleClick}>
            <Box
               sx={{
                  backgroundColor: '#4D7BF1',
                  borderRadius: '100px',
                  width: { width },
                  padding: '9px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                  cursor: 'pointer',
               }}
            >
               {arrow && <ReplyIcon style={{ transform: 'scaleX(-1)', color: '#fff', width: '22px' }} />}

               <Typography
                  sx={{
                     fontFamily: 'Inter',
                     color: '#fff',
                     fontWeight: '700',
                     fontSize: '14px',
                     lineHeight: '18px',
                  }}
               >
                  {buttonText}
               </Typography>
            </Box>
         </Box>
      </>
   );
}
